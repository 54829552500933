
import axios from 'axios';

var cancleTokens = {}

const Animals = {
    state: {
        aliases:[],
    },
    getters: {
        //Получить Алиас по типу
        ALIASES_BY_TYPE: state => type =>{
            
            return state.aliases.filter(elem=>{
                return elem.type == type
            })
            }
    },
    mutations: {
        //Установка списка алиасов
        SET_ALIASES: (state,payload)=>{
            state.aliases = payload
            }
    },
    actions: {
        // Инициация Анималс
        INIT_ANIMALS:async (state, payload) =>{
            return new Promise(async(resolve, reject) =>  {
                // получаем Алиасы
                await state.dispatch('GET_ALIASES')
                resolve('TRUE')
                })
            },
        // Получить алиасы
        GET_ALIASES:(state,payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/animals/v1/aliases",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    var result = response.data.result
                    //Сохраняем алиасы в Алиасы
                    state.commit('SET_ALIASES', result['items'])
                    resolve(response);
                },error =>{reject(error)});
                })
            },
        // Получить список адресов
        GET_ADDRESS:(state,payload) =>{
            if(cancleTokens['get_address']){
                cancleTokens['get_address'].cancel();
             }
             const CancelToken = axios.CancelToken;
             cancleTokens['get_address'] = CancelToken.source();
             console.log(cancleTokens['get_address'])
            return new Promise((resolve, reject) =>  {
                // Отправляем запрос на авторизацию пользователя
                axios.get(state.getters.SERVER_ADRESS+"/animals/v1/address",{
                    cancelToken: cancleTokens['get_address'].token,
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })
            },
        //Получить список животных
        GET_ANIMALS_LIST:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/animals/v1/animals_list",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })
            },
        GET_ANIMALS_LIST_ARCHIVE:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/animals/v1/animals_list_archive",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })
            },
        //Получить карточку животного
        GET_ANIMAL_CARD:(state,payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/animals/v1/animals/cards",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })
            
            },
        //Получить историю животного
        GET_ANIMAL_HISTORY:(state,payload)=>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/animals/v1/animals/history",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })
            },
        //Проверка ИНЖ
        CHECK_ING:(state,payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/animals/v1/animals/ing",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })
            },

        //Активация животного
        ACTIVATION_ANIMAL:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/animals/v1/animals/activation", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
                
            })},
        //Стерилизация животного
        STERILIZATION_ANIMAL:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/animals/v1/animals/sterilization", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Вакцинация животного
        VACTINATION_ANIMAL:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/animals/v1/animals/vactination", payload)
                .then(response => {
                    // В случае успеха
                    resolve(response);
                },error =>{
                    // В случае ошибки
                    reject(error)
                });
            })},
        //Деактивация животного
        DEACTIVATION_ANIMAL:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/animals/v1/animals/deactivation", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Деактивация животного
        IDENTIFICATION_ANIMAL:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/animals/v1/animals/identification", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        GET_VACTINATION_ACT_REPORT:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/animals/reports/v1/vactination_act",{
                    responseType: 'blob',
                    params:payload
                }).then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                     var fileLink = document.createElement('a');

                
                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');
                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = today.getFullYear();
                    today = dd + '-' + mm + '-' + yyyy;

                     fileLink.href = fileURL;
                     fileLink.setAttribute('download', 'Отчет о вакцинации животных'+ today +'.xlsx');
                     document.body.appendChild(fileLink);

                     fileLink.click();
                },error =>{reject(error)});
                })
        },
        GET_ANIMALS_REPORT:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/animals/reports/v1/animals_act",{
                    responseType: 'blob',
                    params:payload
                }).then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                     var fileLink = document.createElement('a');

                
                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');
                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = today.getFullYear();
                    today = dd + '-' + mm + '-' + yyyy;

                     fileLink.href = fileURL;
                     fileLink.setAttribute('download', 'Отчет о животных'+ today +'.xlsx');
                     document.body.appendChild(fileLink);

                     fileLink.click();
                },error =>{reject(error)});
                })
        },
        //Получить карточку инспектора
        GET_INSPECTOR_CARD:(state,payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/animals/v1/inspectors/cards",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })
            
            },
        //
        GET_INSPECTORS_LIST:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/animals/v1/inspectors",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })
            },
    },
       
    modules: {
    }
  }
  export default {
      Animals: Animals
  }